<template>
  <component :is="icon" />
</template>
<script lang="ts" setup>
import { computed, defineAsyncComponent } from 'vue';

defineOptions({
  name: 'FileIcon',
});
const props = defineProps<{
  /**
   * 文件类型
   */
  type: string;
}>();

/**
 * ====================
 *       基本逻辑
 * ====================
 */
const icon = computed(() => {
  switch (props.type) {
    case 'folder':
      return defineAsyncComponent(() => import('./icons/folder.svg'));
    case 'pdf':
      return defineAsyncComponent(() => import('./icons/pdf.svg'));
    case 'xls':
    case 'xlsx':
      return defineAsyncComponent(() => import('./icons/xls.svg'));
    case 'doc':
    case 'docx':
      return defineAsyncComponent(() => import('./icons/doc.svg'));
    case 'txt':
      return defineAsyncComponent(() => import('./icons/txt.svg'));
    default:
      return defineAsyncComponent(() => import('./icons/unknown.svg'));
  }
});
</script>
