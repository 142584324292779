import Layoutblank from '@/layouts/blank.vue';
import Layout from '@/layouts/index.vue';

export default [
  {
    path: '/project',
    component: Layout,
    name: 'Project',
    children: [
      {
        path: 'planning',
        name: 'ProjectPlanning',
        component: () => import('@/pages/project/Planning.vue'),
        meta: { title: '产品企划' },
      },
      {
        path: 'define',
        name: 'ProjectDefine',
        component: () => import('@/pages/project/Define.vue'),
        meta: { title: '产品定义' },
      },
      {
        path: 'rd',
        name: 'ProjectRD',
        component: () => import('@/pages/project/RD.vue'),
        meta: { title: '产品研发' },
      },
      {
        path: 'launch',
        name: 'ProjectLaunch',
        component: () => import('@/pages/project/Launch.vue'),
        meta: { title: '产品上市' },
      },
      {
        path: 'special',
        name: 'ProjectSpecial',
        component: () => import('@/pages/project/Special.vue'),
        meta: { title: '专项专家' },
      },
      {
        path: 'kingDee',
        name: 'ProjectKingDee',
        component: () => import('@/pages/project/KingDee.vue'),
        meta: { title: '金蝶管易云' },
      },
      {
        path: 'huaxizi',
        name: 'ProjectHuaxizi',
        component: () => import('@/pages/project/Huaxizi.vue'),
        meta: { title: '花西子' },
      },
      {
        path: 'chat/:module/:digitId/:sessionId',
        name: 'ProjectChat',
        component: () => import('@/pages/project/Chat.vue'),
        meta: { title: '专家聊天' },
      },

      {
        path: 'chatHistory/:digitId',
        name: 'ProjectChatHistory',
        component: () => import('@/pages/project/ChatHistory.vue'),
        meta: { title: '历史记录' },
      },
      {
        path: 'chatResult/:id',
        name: 'ProjectChatResult',
        component: () => import('@/pages/project/ChatResult.vue'),
        meta: { title: '保存结果' },
      },
      {
        path: 'chatResultEditor/:id',
        name: 'ProjectChatResultEditor',
        component: () => import('@/pages/project/ChatResultEditor.vue'),
        meta: { title: '编辑保存结果' },
      },
    ],
  },
  {
    path: '/project',
    component: Layoutblank,
    name: 'ProjectBlank',
    children: [
      {
        path: 'chatBlank/:module/:digitId/:sessionId',
        name: 'ProjectBlankChat',
        component: () => import('@/pages/project/Chat.vue'),
        meta: { title: '专家聊天' },
      },
    ],
  },
];
