import { RequestOptions } from '@meerkat/utils';

import { request } from '@/utils/request';

import { Category, Position } from './model/commonModel';

/**
 * ====================
 *         基本
 * ====================
 */
/**
 * 获取七牛Token
 * @param params 请求参数
 * @param options 请求配置
 */
export function getQiniuToken(params: { path?: string }, options?: RequestOptions) {
  return request.get<string>({ url: '/common/getToken', params }, options);
}

/**
 * ====================
 *       基础数据
 * ====================
 */
/**
 * 获取职位列表
 * @param options 请求配置
 */
export function getPostList(options?: RequestOptions) {
  return request.get<Position[]>({ url: '/sys/findPostList' }, options);
}

/**
 * 获取品类树
 * @param options 请求配置
 */
export function getCategoryTree(options?: RequestOptions) {
  return request.get<Category[]>({ url: '/sys/findClassTreeList' }, options);
}

/**
 * ====================
 *         通知
 * ====================
 */
/**
 * 获取未读通知个数
 * @param options 请求配置
 */
export function getUnreadMessageCount(options?: RequestOptions) {
  return request.get<number>({ url: '/notice/getNoticeCount' }, options);
}
