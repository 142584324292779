<!-- 到期提醒弹窗 -->
<template>
  <div ref="expiredDialogWrap" class="expired-dialog--wrap">
    <t-dialog
      v-if="userStore.teamInfo"
      v-model:visible="userStore.expiredVisible"
      :attach="() => expiredDialogWrap"
      :width="500"
      :close-btn="false"
      :close-on-esc-keydown="false"
      :close-on-overlay-click="false"
      destroy-on-close
    >
      <div class="flex flex-col items-center text-primary pt-6 relative">
        <div class="text-2xl font-bold mb-2">感谢使用Meerkat AI</div>
        <div class="text-sm">
          <template v-if="userStore.residueDays <= 0">
            {{ userStore.teamInfo.probationFlag === 0 ? '很抱歉，您的试用版已到期' : '很抱歉，您当前的付费版本已到期' }}
          </template>
          <template v-else>
            {{ userStore.teamInfo.probationFlag === 0 ? '您当前购买的版本' : '您的试用版' }}还有<span
              class="text-brand font-bold mx-0.5"
              >{{ userStore.residueDays }}</span
            >天到期
          </template>
        </div>
        <t-image
          v-if="userStore.teamInfo.salePerson"
          :src="userStore.teamInfo.salePerson.wx"
          class="w-[200px] my-6 !bg-transparent"
        />
        <t-tag theme="primary" size="large">微信扫码立即咨询</t-tag>
        <t-button
          class="!absolute top-2 right-2"
          variant="text"
          shape="square"
          @click="userStore.switchExpiredDialogVisible(false)"
        >
          <template #icon><close-icon /></template>
        </t-button>
      </div>
      <div class="h-14 bg-secondarycomponent mt-8 flex items-center justify-center">
        <logo class="text-3xl mr-2" />
        <div>使用Meerkat AI已成为没个爆款设计的必经之路</div>
      </div>
    </t-dialog>
  </div>
</template>
<script setup lang="ts">
import { CloseIcon } from 'tdesign-icons-vue-next';
import { ref } from 'vue';

import Logo from '@/assets/assets-logo.svg?component';
import { useUserStore } from '@/store';

const userStore = useUserStore();

/**
 * ====================
 *       基本逻辑
 * ====================
 */
const expiredDialogWrap = ref<HTMLElement>();
</script>

<style lang="less" scoped>
.expired-dialog--wrap {
  :deep(.t-dialog) {
    padding: 0;
    overflow: hidden;
    border: 0;

    &__header,
    &__footer {
      display: none;
    }

    &__body {
      padding: 0;
    }
  }
}
</style>
