import { defineStore } from 'pinia';

import { getUnreadMessageCount } from '@/api/common';

export const useNotificationStore = defineStore('notification', {
  state: () => ({
    unReadNum: 0,
  }),
  getters: {},
  actions: {
    /**
     * 刷新消息数量
     */
    refreshMessageCount() {
      getUnreadMessageCount().then((res) => {
        this.unReadNum = res;
      });
    },
  },
  persist: false,
});
