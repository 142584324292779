<!-- 脑图节点 -->
<template>
  <t-dropdown
    :options="contextMenu"
    trigger="context-menu"
    :min-column-width="200"
    :max-column-width="200"
    :popup-props="{ visible: showContextMenu, onVisibleChange: onContextMenuVisibleChange }"
  >
    <div class="w-[200px] bg-white rounded p-2 cursor-pointer" @click.stop="onClick" @dblclick.stop="onDblclick">
      <template v-if="onEditTopic">
        <slot v-if="$slots.editor" name="editor" :data="data" :level="level" :edit-done="topicEditDone" />
        <t-input
          v-else
          v-model="topicEdit"
          placeholder="输入文字"
          autofocus
          @blur="topicEditDone"
          @enter="topicEditDone"
        />
      </template>
      <template v-else>
        <slot v-if="$slots.default" :data="data" :level="level" />
        <div v-else class="truncate">{{ props.data.topic === '__EMPTY__' ? '输入文字' : props.data.topic }}</div>
      </template>
    </div>
  </t-dropdown>
</template>

<script setup lang="ts">
import JsMind, { JsMindNode } from 'jsmind';
import { DropdownProps } from 'tdesign-vue-next';
import { computed, nextTick, ref } from 'vue';

const props = defineProps<{
  /**
   * 数据
   */
  data: JsMindNode;
  /**
   * 脑图对象
   */
  mind: JsMind;
  /**
   * 最高层级
   */
  maxLevel: number;
  /**
   * 插入子节点操作的名称或是否显示
   */
  addChildMenu?: string | ((node: JsMindNode, level: number) => string);
  /**
   * 插入同级节点操作的名称或是否显示
   */
  addSiblingMenu?: string | ((node: JsMindNode, level: number) => string);
  /**
   * 插入父节点操作的名称或是否显示
   */
  addParentMenu?: string | ((node: JsMindNode, level: number) => string);
  /**
   * 接管编辑节点
   */
  nodeEdit?: boolean | ((node: JsMindNode, level: number) => boolean);
}>();
const emits = defineEmits<{
  /**
   * 选中回调
   * @param node 节点数据
   */
  selected: [node: JsMindNode];
  /**
   * 添加节点
   * @param node 节点数据
   * @param type 类型
   * @param level 层级
   */
  add: [node: JsMindNode, type: 'child' | 'sibling' | 'parent', level: number];
  /**
   * 节点文本更新
   * @param node 节点数据
   */
  topicUpdated: [node: JsMindNode];
  /**
   * 删除节点
   * @param node 节点数据
   */
  remove: [node: JsMindNode];
}>();

/**
 * ====================
 *       基本逻辑
 * ====================
 */
const level = computed(() => {
  let l = 1;
  let node = props.data;
  while (node.parent) {
    l++;
    node = node.parent;
  }
  return l;
});
/**
 * 点击事件
 */
function onClick() {
  showContextMenu.value = false;
  emits('selected', props.mind.get_node(props.data.id));
}

/**
 * ====================
 *       右键菜单
 * ====================
 */
const addSiblingMenu = computed<DropdownProps['options']>(() => {
  let content = '插入同级节点';
  if (typeof props.addSiblingMenu === 'function') {
    const newContent = props.addSiblingMenu(props.data, level.value);
    if (!newContent) {
      return [];
    }
    content = newContent;
  } else if (typeof props.addSiblingMenu === 'string') {
    content = props.addSiblingMenu;
  }
  return [
    {
      content,
      value: 'addSibling',
      theme: 'default',
      onClick: () => emits('add', props.data, 'sibling', level.value),
    },
  ];
});
const addChildMenu = computed<DropdownProps['options']>(() => {
  if (level.value >= props.maxLevel) {
    return [];
  }

  let content = '插入子节点';
  if (typeof props.addChildMenu === 'function') {
    const newContent = props.addChildMenu(props.data, level.value);
    if (!newContent) {
      return [];
    }
    content = newContent;
  } else if (typeof props.addChildMenu === 'string') {
    content = props.addChildMenu;
  }
  return [
    {
      content,
      value: 'addChild',
      theme: 'default',
      divider: addParentMenu.value.length === 0 && !props.data.isroot,
      onClick: () => emits('add', props.data, 'child', level.value),
    },
  ];
});
const addParentMenu = computed<DropdownProps['options']>(() => {
  let content = '插入父节点';
  if (typeof props.addParentMenu === 'function') {
    const newContent = props.addParentMenu(props.data, level.value);
    if (!newContent) {
      return [];
    }
    content = newContent;
  } else if (typeof props.addParentMenu === 'string') {
    content = props.addParentMenu;
  }
  return [
    {
      content,
      value: 'addParent',
      theme: 'default',
      divider: true,
      onClick: () => emits('add', props.data, 'parent', level.value),
    },
  ];
});
const contextMenu = computed<DropdownProps['options']>(() => {
  if (props.data.isroot) {
    return [...addChildMenu.value];
  }
  return [
    ...addSiblingMenu.value,
    ...addChildMenu.value,
    ...addParentMenu.value,
    { content: '删除', value: 'delete', theme: 'error', onClick: () => emits('remove', props.data) },
  ];
});
const showContextMenu = ref(false);
/**
 * 切换右键菜单显示状态
 * @param visible 是否可见
 */
function onContextMenuVisibleChange(visible: boolean) {
  showContextMenu.value = visible;
}

/**
 * ====================
 *       编辑Topic
 * ====================
 */
const topicEdit = ref('');
const onEditTopic = ref(false);
/**
 * 双击事件
 */
function onDblclick() {
  if (typeof props.nodeEdit === 'boolean' && props.nodeEdit === false) {
    return;
  }
  if (typeof props.nodeEdit === 'function' && props.nodeEdit(props.data, level.value) === false) {
    return;
  }

  topicEdit.value = props.data.topic !== '__EMPTY__' ? props.data.topic : '';
  onContextMenuVisibleChange(false);
  onEditTopic.value = true;
}
/**
 * topic编辑完成
 * @param topic 编辑后的topic
 */
function topicEditDone(topic: string) {
  onEditTopic.value = false;

  // 防止界面更新和jsmind更新冲突报错
  nextTick(() => {
    emits('topicUpdated', { ...props.data, topic: topic || '__EMPTY__' });
  });
}

/**
 * ====================
 *       工具方法
 * ====================
 */
</script>

<style lang="less" scoped></style>
