<!-- 展开/收起动画 -->
<template>
  <transition
    name="collapse"
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @leave="leave"
    @after-leave="afterLeave"
  >
    <slot />
  </transition>
</template>

<script lang="ts" setup>
defineOptions({
  name: 'CollapseTransition',
});

const props = withDefaults(
  defineProps<{
    direction?: 'vertical' | 'horizontal';
  }>(),
  { direction: 'vertical' },
);

const beforeEnter = (el: HTMLElement) => {
  if (props.direction === 'vertical') {
    el.dataset.oldPaddingTop = el.style.paddingTop;
    el.dataset.oldPaddingBottom = el.style.paddingBottom;

    el.style.height = '0';
    el.style.paddingTop = '0';
    el.style.paddingBottom = '0';
  } else if (props.direction === 'horizontal') {
    el.dataset.oldPaddingLeft = el.style.paddingLeft;
    el.dataset.oldPaddingRight = el.style.paddingRight;

    el.style.width = '0';
    el.style.paddingLeft = '0';
    el.style.paddingRight = '0';
  }
};
const enter = (el: HTMLElement) => {
  el.dataset.oldOverflow = el.style.overflow;

  if (props.direction === 'vertical') {
    el.style.height = `${el.scrollHeight}px`;
    el.style.paddingTop = el.dataset.oldPaddingTop;
    el.style.paddingBottom = el.dataset.oldPaddingBottom;
  } else if (props.direction === 'horizontal') {
    el.style.width = `${el.scrollWidth}px`;
    el.style.paddingLeft = el.dataset.oldPaddingLeft;
    el.style.paddingRight = el.dataset.oldPaddingRight;
  }

  el.style.overflow = 'hidden';
};
const afterEnter = (el: HTMLElement) => {
  if (props.direction === 'vertical') {
    el.style.height = '';
  } else if (props.direction === 'horizontal') {
    el.style.width = '';
  }

  el.style.overflow = el.dataset.oldOverflow;
};
const beforeLeave = (el: HTMLElement) => {
  if (props.direction === 'vertical') {
    el.dataset.oldPaddingTop = el.style.paddingTop;
    el.dataset.oldPaddingBottom = el.style.paddingBottom;
    el.dataset.oldOverflow = el.style.overflow;

    el.style.height = `${el.scrollHeight}px`;
    el.style.overflow = 'hidden';
  } else if (props.direction === 'horizontal') {
    el.dataset.oldPaddingLeft = el.style.paddingLeft;
    el.dataset.oldPaddingRight = el.style.paddingRight;
    el.dataset.oldOverflow = el.style.overflow;

    el.style.width = `${el.scrollWidth}px`;
    el.style.overflow = 'hidden';
  }
};
const leave = (el: HTMLElement) => {
  if (el.scrollHeight !== 0 && props.direction === 'vertical') {
    el.style.height = '0';
    el.style.paddingTop = '0';
    el.style.paddingBottom = '0';
  } else if (el.scrollHeight !== 0 && props.direction === 'horizontal') {
    el.style.width = '0';
    el.style.paddingLeft = '0';
    el.style.paddingRight = '0';
  }
};
const afterLeave = (el: HTMLElement) => {
  if (props.direction === 'vertical') {
    el.style.height = '';
    el.style.overflow = el.dataset.oldOverflow;
    el.style.paddingTop = el.dataset.oldPaddingTop;
    el.style.paddingBottom = el.dataset.oldPaddingBottom;
  } else if (props.direction === 'horizontal') {
    el.style.width = '';
    el.style.overflow = el.dataset.oldOverflow;
    el.style.paddingLeft = el.dataset.oldPaddingLeft;
    el.style.paddingRight = el.dataset.oldPaddingRight;
  }
};
</script>

<style lang="less" scoped>
.collapse-enter-active,
.collapse-leave-active {
  transition:
    height var(--transition-default) 0.2s,
    padding var(--transition-default) 0.2s;
}
</style>
