<!-- 页框 -->
<template>
  <div v-if="props.loading" class="pt-[20%] text-center">
    <t-loading size="large" v-bind="props.loadingProps" />
  </div>
  <slot v-else />
</template>

<script setup lang="ts">
import { TdLoadingProps } from 'tdesign-vue-next';

defineOptions({
  name: 'PageLayout',
});
const props = defineProps<{
  /**
   * 是否正在加载
   */
  loading: boolean;
  /**
   *  Loading 组件透传
   */
  loadingProps?: TdLoadingProps;
}>();
</script>

<style scoped></style>
