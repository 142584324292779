<template>
  <div class="px-2 py-2 gap-2 flex items-center cursor-pointer transition-all hover:bg-brand-focus rounded">
    <div class="w-6 h-6 bg-gray-2 text-gray-8 rounded-full justify-center items-center flex text-xs mr-2">
      <slot name="icon" />
    </div>
    <div class="flex-1 text-gray-8 text-sm">
      <template v-if="$slots.label"><slot name="label" /></template>
      <template v-else>{{ props.label }}</template>
    </div>
    <template v-if="$slots.suffix"><slot name="suffix" /></template>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    /**
     * 文本
     */
    label?: string;
    /**
     * 值
     */
    value?: string;
  }>(),
  {},
);
</script>

<style lang="less" scoped />
