<template>
  <div>
    <div class="flex items-center justify-between px-4">
      <t-badge dot :count="notificationStore.unReadNum" :offset="[1, 2]">
        <div class="capsule" @click="$router.push('/notification')">
          <notification-icon />
        </div>
      </t-badge>
      <div class="capsule" @click="$router.push('/subscribe')">
        <creditcard-icon />
      </div>
      <div class="capsule" @click="$router.push('/recycleBin')">
        <delete-icon />
      </div>
      <t-popup>
        <template #content>
          <div class="flex items-center justify-center py-4">
            <logo class="text-3xl mr-2" />
            <div class="text-xl">Meerkat AI</div>
          </div>
          <t-image
            :src="userStore.teamInfo.salePerson ? userStore.teamInfo.salePerson.wx : DefaultSaleWxJpg"
            class="w-[200px] !bg-transparent"
          />
        </template>
        <div class="capsule">
          <logo-wechat-icon />
        </div>
      </t-popup>
      <div class="capsule" @click="toUserManual">
        <help-icon />
      </div>
    </div>
    <div class="px-2">
      <t-divider class="!mt-4 !mb-2" />
    </div>
    <div class="pb-4 px-2 space-y-1">
      <side-bottom-item label="我的团队" value="17/20" @click="$router.push('/system/group')">
        <template #icon><usergroup-icon /></template>
        <template #suffix>
          <div class="text-placeholder text-xs">
            {{ userStore.teamInfo.consumeNum }}/{{ userStore.teamInfo.number }}
          </div>
        </template>
      </side-bottom-item>
      <t-dropdown trigger="hover" placement="top-left" :max-column-width="1000">
        <side-bottom-item :label="userStore.name ?? '-'" @click="$router.push('/user')">
          <template #icon><user-icon /></template>
          <template #suffix>
            <right-icon />
          </template>
        </side-bottom-item>
        <t-dropdown-menu>
          <t-dropdown-item value="userCenter" :divider="true" @click="$router.push('/user')">
            <template #prefixIcon><people-icon /></template>
            <span class="flex items-center">
              个人中心
              <arrow-right-icon class="text-base ml-6" />
            </span>
          </t-dropdown-item>
          <t-dropdown-item value="logout" theme="error" @click="$router.push('/login')">
            <template #prefixIcon><logout-icon /></template>
            退出登录
          </t-dropdown-item>
        </t-dropdown-menu>
      </t-dropdown>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  CreditcardIcon,
  DeleteIcon,
  HelpIcon,
  LogoWechatIcon,
  NotificationIcon,
  UsergroupIcon,
  UserIcon,
} from 'tdesign-icons-vue-next';
import { onBeforeMount } from 'vue';

import Logo from '@/assets/assets-logo.svg';
import DefaultSaleWxJpg from '@/assets/default-sale-wx.jpg';
import ArrowRightIcon from '@/assets/icons/arrow-right.svg';
import LogoutIcon from '@/assets/icons/logout.svg';
import PeopleIcon from '@/assets/icons/people.svg';
import RightIcon from '@/assets/icons/right.svg';
import { useNotificationStore, useUserStore } from '@/store';

import SideBottomItem from './Item.vue';

const userStore = useUserStore();
const notificationStore = useNotificationStore();

onBeforeMount(() => {
  notificationStore.refreshMessageCount();
});

/**
 * ====================
 *       基本逻辑
 * ====================
 */
/**
 * 跳转到使用手册
 */
function toUserManual() {
  window.open('https://v18exje5k2i.feishu.cn/docx/Lx9ydYbCoo6Gu0xBcUsc9hybnkf');
}
</script>

<style lang="less" scoped>
.capsule {
  @apply w-6 h-6 bg-gray-2 text-gray-8 rounded-full justify-center items-center flex text-xs cursor-pointer transition-all hover:bg-brand-focus;
}
</style>
