<!-- 录入成功显示 -->
<template>
  <div class="result">
    <check-circle-icon class="text-success" size="64px" />
    <div class="result__title">{{ props.title }}</div>
    <div v-if="props.tip" class="result__tip">{{ props.tip }}</div>
    <div v-if="$slots.content" class="result__content">
      <slot name="content" />
    </div>
    <div class="result__action-list flex gap-3">
      <slot name="actions">
        <t-button v-if="props.showBack" theme="default" @click="backAction">{{ props.backTip }}</t-button>
        <t-button v-if="props.showGo" theme="primary" @click="goAction">{{ props.goTip }}</t-button>
      </slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { CheckCircleIcon } from 'tdesign-icons-vue-next';

defineOptions({
  name: 'SuccessResult',
});
const props = withDefaults(
  defineProps<{
    /**
     * 标题
     */
    title: string;
    /**
     * 简介
     */
    tip?: string;
    /**
     * 是否展示返回按钮
     * @default ```true```
     */
    showBack?: boolean;
    /**
     * 返回提示
     */
    backTip?: string;
    /**
     * 是否展示跳转按钮
     * @default ```true```
     */
    showGo?: boolean;
    /**
     * 新跳转提示
     */
    goTip?: string;
  }>(),
  { showBack: true, backTip: '返回', showGo: true, goTip: '前往详情' },
);

const emits = defineEmits(['back', 'go']);

const backAction = () => {
  emits('back');
};
const goAction = () => {
  emits('go');
};
</script>

<style lang="less" scoped>
.result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 200px 0 100px;

  &__icon {
    width: 64px;
  }

  &__title {
    margin-top: 24px;
    font-size: 20px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.9);
    line-height: 28px;
  }

  &__tip {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    line-height: 22px;
  }

  &__content {
    margin-top: 8px;
  }

  &__action-list {
    margin-top: 32px;
  }
}
</style>
