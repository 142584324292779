import 'tdesign-vue-next/es/style/index.css';
import '@/style/index.less';
import './permission';

import * as Sentry from '@sentry/vue';
import { Loading } from 'tdesign-vue-next';
import { createApp } from 'vue';

import App from './App.vue';
import router from './router';
import { store } from './store';

const app = createApp(App);

// Sentry监控初始化
Sentry.init({
  app,
  dsn: 'https://6b0264f3b31d0d134e7392482b76f006@o4507094844178432.ingest.us.sentry.io/4507094846341120',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: import.meta.env.VITE_SENTRY_ENABLED === 'true',
  ignoreErrors: ['认证信息已失效，请重新登录'],
});

// Sentry.addEventProcessor((event, hint) => {
//   console.log(event);
//   console.log(hint);
//   event.contexts.extra = { a: 234 };
//   // Add anything to the event here
//   // returning `null` will drop the event
//   return event;
// });

app.use(Loading);
app.use(store);
app.use(router);

app.mount('#app');
