import Layout from '@/layouts/index.vue';

export default [
  {
    path: '',
    component: Layout,
    redirect: '/dashboard',
    name: 'Index',
    children: [
      /**
       * ====================
       *       主要页面
       * ====================
       */
      {
        path: 'dashboard',
        name: 'Home',
        component: () => import('@/pages/base/index.vue'),
        meta: { title: '欢迎使用MEERKAT AI' },
      },
      /**
       * ====================
       *      其他基本页面
       * ====================
       */
      {
        path: 'subscribe',
        name: 'Subscribe',
        component: () => import('@/pages/base/Subscribe.vue'),
        meta: { title: '订阅token数' },
      },
      {
        path: 'ordermanagement',
        name: 'OrderManagement',
        component: () => import('@/pages/base/OrderManagement.vue'),
        meta: { title: '订单管理' },
      },
      {
        path: 'notification',
        name: 'Notification',
        component: () => import('@/pages/base/Notification.vue'),
        meta: { title: '消息通知' },
      },
      {
        path: 'recycleBin',
        name: 'RecycleBin',
        component: () => import('@/pages/base/RecycleBin.vue'),
        meta: { title: '回收站' },
      },
      {
        path: 'noAccess',
        name: 'NoAccess',
        component: () => import('@/pages/NoAccess.vue'),
        meta: { title: '无权限' },
      },
    ],
  },
];
