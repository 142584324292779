import * as Sentry from '@sentry/vue';
import uaParser from 'ua-parser-js';
import { ref } from 'vue';

import { BrowserCompatibilityConfig } from './types';

export * from './types';

/**
 * 浏览器检测钩子
 * @param conf 配置
 */
export const useBrowserCompatibility = (conf?: BrowserCompatibilityConfig) => {
  const config: BrowserCompatibilityConfig = { ...conf };
  const browserVersions: Record<string, number> = {
    edge: 94,
    chrome: 91,
    blink: 91, // 降权使用
    firefox: 83,
    safari: 14.1,
    opera: 78,
    qqbrowser: 11,
    wechat: 6,
    ...config.browserVersions,
  };

  const incompatible = ref(false);

  /**
   * 浏览器检测
   */
  const browserCheck = () => {
    const originalUa = uaParser();
    const ua = uaParser(originalUa.ua.replace('MetaSr', '')); // 搜狗浏览器的坑

    try {
      let browerVersion = browserVersions[ua.browser.name.toLowerCase()];
      let versionArr = ua.browser.version ? ua.browser.version.split('.') : [];
      if (browerVersion === undefined) {
        // 如果查不到就降权查询 engine
        browerVersion = browserVersions[ua.engine.name.toLowerCase()];
        versionArr = ua.engine.version ? ua.engine.version.split('.') : [];
      }

      const major = Number(`${versionArr[0]}.${versionArr[1] ?? '0'}`);

      if (browerVersion === undefined || browerVersion > major) {
        incompatible.value = true;
        Sentry.captureMessage(`UA检测不兼容, 原始UA：${originalUa.ua}`);
      }
    } catch (error) {
      Sentry.captureMessage(`UA检测错误：${error}, 原始UA：${originalUa.ua}`);
    }
  };

  return { incompatible, browserCheck };
};
