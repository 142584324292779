<template>
  <div v-if="userStore.loginFrom === 'self'" class="min-w-[1440px]">
    <div
      class="h-screen fixed top-0 left-0 z-20 bg-white w-[232px] flex flex-col gap-6 border-0 border-r border-stroke border-solid"
    >
      <div class="py-6 text-center cursor-pointer" @click="$router.push('/dashboard')">
        <logo-full class="w-[180px] h-[40px]" />
      </div>

      <div class="menus overflow-y-scroll flex-1 h-full">
        <!-- 主菜单 -->
        <menu-group :model-value="menuValue" @change="onMenuChange">
          <menu-item v-if="userStore.hasPermission('Home')" value="/dashboard" label="首页">
            <template #icon="{ checked }">
              <home-active-icon v-if="checked" />
              <home-icon v-else />
            </template>
          </menu-item>
          <menu-item v-if="userStore.hasPermission('ProjectPlanning')" value="/project/planning" label="产品企划">
            <template #icon="{ checked }">
              <project-market-active-icon v-if="checked" />
              <project-market-icon v-else />
            </template>
          </menu-item>
          <menu-item v-if="userStore.hasPermission('ProjectDefine')" value="/project/define" label="产品定义">
            <template #icon="{ checked }">
              <project-define-active-icon v-if="checked" />
              <project-define-icon v-else />
            </template>
          </menu-item>
          <menu-item v-if="userStore.hasPermission('ProjectRD')" value="/project/rd" label="产品研发">
            <template #icon="{ checked }">
              <project-competitor-active-icon v-if="checked" />
              <project-competitor-icon v-else />
            </template>
          </menu-item>
          <menu-item v-if="userStore.hasPermission('ProjectLaunch')" value="/project/launch" label="产品上市">
            <template #icon="{ checked }">
              <project-framework-active-icon v-if="checked" />
              <project-framework-icon v-else />
            </template>
          </menu-item>
          <menu-item v-if="userStore.hasPermission('ProjectSpecial')" value="/project/special" label="专项专家">
            <template #icon="{ checked }">
              <project-scene-active-icon v-if="checked" />
              <project-scene-icon v-else />
            </template>
          </menu-item>
          <menu-item v-if="userStore.hasPermission('ProjectHuaxizi')" value="/project/huaxizi" label="花西子">
            <template #icon="{ checked }">
              <project-scene-active-icon v-if="checked" />
              <project-scene-icon v-else />
            </template>
          </menu-item>
          <menu-item v-if="userStore.hasPermission('SpaceKnowledge')" value="/space/knowledge" label="知识库">
            <template #icon="{ checked }">
              <data-active-icon v-if="checked" />
              <data-icon v-else />
            </template>
          </menu-item>
          <menu-item
            v-if="userStore.hasPermission('MaoDb')"
            value="DBGpt"
            label="数据分析师"
            href="https://maodb.data-vib.com/"
          >
            <template #icon="{ checked }">
              <data-active-icon v-if="checked" />
              <data-icon v-else />
            </template>
          </menu-item>
        </menu-group>
      </div>
      <side-bottom />
    </div>
    <div class="ml-[232px] min-h-screen bg-secondarycontainer">
      <router-view v-slot="{ Component }">
        <component :is="Component" :key="activeRouteFullPath" />
      </router-view>
    </div>
  </div>
  <div v-else class="min-h-screen bg-secondarycontainer">
    <router-view v-slot="{ Component }">
      <component :is="Component" :key="activeRouteFullPath" />
    </router-view>
  </div>
</template>

<script setup lang="ts">
import '@/style/layout.less';

import { computed, defineAsyncComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import LogoFull from '@/assets/assets-logo-full.svg';
import { useUserStore } from '@/store';

import MenuGroup from './components/menu/index.vue';
import MenuItem from './components/menu/MenuItem.vue';
// import MenuItemCustom from './components/menu/MenuItemCustom.vue';
// import SubMenu from './components/menu/SubMenu.vue';
import SideBottom from './components/side-bottom/index.vue';

const route = useRoute();
const router = useRouter();
const userStore = useUserStore();

// 主菜单图标
const HomeIcon = defineAsyncComponent(() => import('@/assets/nav-icons/home.svg'));
const HomeActiveIcon = defineAsyncComponent(() => import('@/assets/nav-icons/home-active.svg'));
// const ProjectIcon = defineAsyncComponent(() => import('@/assets/nav-icons/project.svg'));
// const ProjectActiveIcon = defineAsyncComponent(() => import('@/assets/nav-icons/project-active.svg'));
const DataIcon = defineAsyncComponent(() => import('@/assets/nav-icons/data.svg'));
const DataActiveIcon = defineAsyncComponent(() => import('@/assets/nav-icons/data-active.svg'));
// const ProjectInfoIcon = defineAsyncComponent(() => import('@/assets/nav-icons/project-info.svg'));
// const ProjectInfoActiveIcon = defineAsyncComponent(() => import('@/assets/nav-icons/project-info-active.svg'));
const ProjectMarketIcon = defineAsyncComponent(() => import('@/assets/nav-icons/project-market.svg'));
const ProjectMarketActiveIcon = defineAsyncComponent(() => import('@/assets/nav-icons/project-market-active.svg'));
const ProjectCompetitorIcon = defineAsyncComponent(() => import('@/assets/nav-icons/project-competitor.svg'));
const ProjectCompetitorActiveIcon = defineAsyncComponent(
  () => import('@/assets/nav-icons/project-competitor-active.svg'),
);
const ProjectSceneIcon = defineAsyncComponent(() => import('@/assets/nav-icons/project-scene.svg'));
const ProjectSceneActiveIcon = defineAsyncComponent(() => import('@/assets/nav-icons/project-scene-active.svg'));
// const ProjectMockcusIcon = defineAsyncComponent(() => import('@/assets/nav-icons/project-mockcus.svg'));
// const ProjectMockcusActiveIcon = defineAsyncComponent(() => import('@/assets/nav-icons/project-mockcus-active.svg'));
// const ProjectOriginalityIcon = defineAsyncComponent(() => import('@/assets/nav-icons/project-originality.svg'));
// const ProjectOriginalityActiveIcon = defineAsyncComponent(
//   () => import('@/assets/nav-icons/project-originality-active.svg'),
// );
// const ProjectConceptIcon = defineAsyncComponent(() => import('@/assets/nav-icons/project-concept.svg'));
// const ProjectConceptActiveIcon = defineAsyncComponent(() => import('@/assets/nav-icons/project-concept-active.svg'));
const ProjectFrameworkIcon = defineAsyncComponent(() => import('@/assets/nav-icons/project-framework.svg'));
const ProjectFrameworkActiveIcon = defineAsyncComponent(
  () => import('@/assets/nav-icons/project-framework-active.svg'),
);
const ProjectDefineIcon = defineAsyncComponent(() => import('@/assets/nav-icons/project-define.svg'));
const ProjectDefineActiveIcon = defineAsyncComponent(() => import('@/assets/nav-icons/project-define-active.svg'));

/**
 * ====================
 *       基本逻辑
 * ====================
 */
const activeRouteFullPath = computed(() => {
  const router = useRouter();
  return router.currentRoute.value.fullPath;
});
const menuValue = computed(() => {
  if (route.path.includes('/project/chat/planning/')) {
    return '/project/planning';
  }
  if (route.path.includes('/project/chat/define/')) {
    return '/project/define';
  }
  if (route.path.includes('/project/chat/rd/')) {
    return '/project/rd';
  }
  if (route.path.includes('/project/chat/launch/')) {
    return '/project/launch';
  }
  if (route.path.includes('/project/chat/special/')) {
    return '/project/special';
  }
  if (route.path.includes('/project/chat/huaxizi/')) {
    return '/project/huaxizi';
  }
  return route.path;
});
/**
 * 菜单变更回调
 * @param value 值
 */
const onMenuChange = (value: string) => {
  router.push(value);
};
</script>

<style lang="less" scoped>
@import '@/style/mixins/scrollbar.less';

.menus {
  .scrollbar();
}
</style>
