import Layoutblank from '@/layouts/blank.vue';

export default [
  {
    path: '/patent',
    component: Layoutblank,
    name: 'PatentBlank',
    children: [
      {
        path: 'detail/:patentId/:patentNumber/:similarity',
        name: 'PatentDetail',
        component: () => import('@/pages/patent/PatentDetail.vue'),
        meta: { title: '专利详情' },
      },
      {
        path: 'interpret/:patentListStr',
        name: 'PatentInterpret',
        component: () => import('@/pages/patent/PatentInterpret.vue'),
        meta: { title: '专利解读' },
      },
    ],
  },
];
