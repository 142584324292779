import 'nprogress/nprogress.css'; // progress bar style

import Cookies from 'js-cookie';
import NProgress from 'nprogress'; // progress bar

import router from '@/router';
import { useUserStore } from '@/store';

NProgress.configure({ showSpinner: false });

router.beforeEach(async (to, from, next) => {
  NProgress.start();

  const userStore = useUserStore();
  const { whiteListRouters } = userStore;

  // 进入登录页面不处理直接进入
  if (['/login', '/kingDee'].includes(to.path)) {
    const domain = import.meta.env.VITE_COOKIE_DOMAIN;
    Cookies.withAttributes({ domain }).remove('sassToken');
    userStore.token = undefined;
    userStore.loginFrom = undefined;
    next();
    return;
  }

  // 未登录但在白名单内直接进入
  if (!userStore.token && whiteListRouters.includes(to.path)) {
    next();
    return;
  }

  // 未登录跳转登录页面（不在白名单）
  if (!userStore.token) {
    next('/login');
    return;
  }

  // 获取登录用户信息
  try {
    await userStore.getUserInfo();
    await userStore.getTeamInfo();
  } catch (error) {
    MessagePlugin.warning(error.message);
    next('/login');
    return;
  }

  // 权限判断
  if (!userStore.hasPermission(to.name as string)) {
    next('/noAccess');
    return;
  }

  // 404页面跳转由 router 接管，这里无需处理
  // 正常跳转
  next();
});

router.afterEach((to) => {
  if (to.path === '/login') {
    const userStore = useUserStore();

    userStore.logout();
  }

  NProgress.done();
});
