<!-- 脑图 -->
<template>
  <div ref="mindRef" class="mind w-full h-full cursor-move" @click="onClick" />
</template>

<script setup lang="ts">
import 'jsmind/style/jsmind.css';

import JsMind, { JsMindData, JsMindNode, JsMindOptions } from 'jsmind';
import { nanoid } from 'nanoid';
import { DialogPlugin } from 'tdesign-vue-next';
import { h, onMounted, ref, render } from 'vue';

import Node from './Node.vue';

defineOptions({
  name: 'Mind',
});
const modelValue = defineModel<JsMindData>('modelValue', { required: true });
const props = withDefaults(
  defineProps<{
    /**
     * JSMind配置项
     */
    mindOptions?: Omit<JsMindOptions, 'theme' | 'container' | 'editable' | 'view'>;
    /**
     * 最高层级
     * @default `Infinity`
     */
    maxLevel?: number;
    /**
     * 接管插入孩子节点
     * @param node 节点数据
     * @param mind 脑图控制器
     * @param level 层级
     */
    addChild?: (node: JsMindNode, mind: JsMind, level: number) => void;
    /**
     * 插入子节点操作的名称或是否显示
     */
    addChildMenu?: string | ((node: JsMindNode, level: number) => string);
    /**
     * 接管插入同级节点
     * @param node 节点数据
     * @param mind 脑图控制器
     * @param level 层级
     */
    addSibling?: (node: JsMindNode, mind: JsMind, level: number) => void;
    /**
     * 插入同级节点操作的名称或是否显示
     */
    addSiblingMenu?: string | ((node: JsMindNode, level: number) => string);
    /**
     * 插入父节点操作的名称或是否显示
     */
    addParentMenu?: string | ((node: JsMindNode, level: number) => string);
    /**
     * 接管编辑节点
     */
    nodeEdit?: (node: JsMindNode, level: number) => boolean;
  }>(),
  { maxLevel: Infinity },
);
const slots = defineSlots<{
  /**
   * 节点
   * @param data 节点数据
   * @param level 级别
   */
  node(props: { data: JsMindNode; level: number }): any;
  /**
   * 节点编辑状态
   * @param data 节点数据
   * @param level 级别
   * @param editDone 编辑完成调用
   */
  nodeEditor(props: { data: JsMindNode; level: number; editDone: (topic: string) => void }): any;
}>();

onMounted(() => {
  mind = new JsMind({
    container: mindRef.value,
    theme: 'default',
    editable: true,
    view: {
      line_width: 1,
      engine: 'svg',
      draggable: true,
      hide_scrollbars_when_draggable: true,
      hmargin: 0,
      custom_node_render(jm, element, node) {
        render(
          h(
            Node,
            {
              mind,
              data: node,
              maxLevel: props.maxLevel,
              addChildMenu: props.addChildMenu,
              addSiblingMenu: props.addSiblingMenu,
              addParentMenu: props.addParentMenu,
              nodeEdit: props.nodeEdit,
              onSelected: nodeSelected,
              onAdd: addNode,
              onTopicUpdated: updateNodeTopic,
              onRemove: removeNode,
            },
            { default: slots.node ?? undefined, editor: slots.nodeEditor ?? undefined },
          ),
          element,
        );
        return true;
      },
    },
    ...props.mindOptions,
    shortcut: { enable: false },
  });

  mind.show(modelValue.value);
});

/**
 * ====================
 *       基本逻辑
 * ====================
 */
const mindRef = ref<HTMLElement>();
let mind: JsMind;
const selectedNode = ref<JsMindNode>();
/**
 * 节点选中事件
 * @param node 节点
 */
function nodeSelected(node: JsMindNode) {
  selectedNode.value = node;
}
/**
 * 外框点击回调
 */
function onClick() {
  selectedNode.value = undefined;
}
/**
 * 更新绑定值
 */
function updateValue() {
  const data = mind.get_data();
  modelValue.value = data;
}

/**
 * ====================
 *       编辑节点
 * ====================
 */
/**
 * 添加节点
 * @param node 节点
 * @param type 类型
 * @param level 层级
 */
function addNode(node: JsMindNode, type: 'child' | 'sibling' | 'parent', level: number) {
  const id = nanoid();
  let direction: JsMindNode['direction'];
  switch (type) {
    case 'child':
      if (props.addChild) {
        props.addChild(node, mind, level);
        break;
      }

      mind.add_node(node, id, '__EMPTY__');
      break;

    case 'sibling':
      if (props.addSibling) {
        props.addSibling(node, mind, level);
        break;
      }

      mind.insert_node_after(node, id, '__EMPTY__');
      break;

    case 'parent':
      if ((node.direction as unknown as number) === -1) {
        direction = 'left';
      } else if ((node.direction as unknown as number) === 1) {
        direction = 'right';
      }

      mind.add_node(node.parent, id, '__EMPTY__', {}, direction);
      mind.move_node(node, '', id);
      break;

    default:
      break;
  }

  updateValue();
}
/**
 * 更新节点Topic
 * @param node 节点
 */
function updateNodeTopic(node: JsMindNode) {
  mind.update_node(node.id, node.topic);

  updateValue();
}
/**
 * 移除节点
 * @param node 节点
 */
function removeNode(node: JsMindNode) {
  const confirm = DialogPlugin.confirm({
    header: '删除节点',
    body: `是否确认删除节点【${node.topic === '__EMPTY__' ? '空' : node.topic}】，及其包含的所有子节点?`,
    theme: 'warning',
    onConfirm: () => {
      mind.remove_node(node);

      confirm.hide();

      updateValue();
    },
  });
}

defineExpose({ updateNodeTopic });
</script>

<style lang="less" scoped>
.mind :deep(jmnodes.theme-default) {
  jmnode {
    padding: 0;
    background-color: unset;
    color: unset;
    border-radius: unset;
    box-shadow: unset;
    font: unset;
  }

  jmexpander {
    width: 10px;
    height: 9px;
    border-radius: 999px;
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: unset;
    line-height: normal;
    padding-left: 1px;
    padding-bottom: 2px;
  }
}
</style>
