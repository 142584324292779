<template>
  <div class="tdesign-wrapper">
    <router-view v-slot="{ Component }">
      <component :is="Component" :key="activeRouteFullPath" />
    </router-view>
  </div>
</template>

<script setup lang="ts">
import '@/style/layout.less';

import { computed } from 'vue';
import { useRouter } from 'vue-router';
/**
 * ====================
 *       基本逻辑
 * ====================
 */
const activeRouteFullPath = computed(() => {
  const router = useRouter();
  return router.currentRoute.value.fullPath;
});
</script>

<style lang="less" scoped>
.tdesign-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
