import { RequestOptions } from '@meerkat/utils';

import { PageSearchParams, PaginationData } from '@/types/interface';
import { request } from '@/utils/request';

import { AddMemberRequest, Notice, PageMembersResponse, Recycle, TeamInfo, UpdateMember } from './model/teamModel';

/**
 * ====================
 *        系统设置
 * ====================
 */
/**
 * 获取团队配置信息
 * @param options 请求配置
 */
export function getTeamInfo(options?: RequestOptions) {
  return request.get<TeamInfo>({ url: '/user/getSysUserConfig' }, options);
}

/**
 * ====================
 *        我的团队
 * ====================
 */
/**
 * 获取团队成员列表
 * @param options 请求配置
 */
export function getTeamMembers(params: PageSearchParams, options?: RequestOptions) {
  return request.get<PageMembersResponse>({ url: '/user/getSysUserList', params }, options);
}

/**
 * 修改团队成员信息
 * @param data 参数
 * @param options 配置
 */
export function updateTeamMember(data: UpdateMember, options?: RequestOptions) {
  return request.put({ url: '/user/updateSysUser', data }, options);
}

/**
 * 移出团队成员
 * @param id 参数
 * @param options 配置
 */
export function removeTeamMember(id: number, options?: RequestOptions) {
  return request.delete({ url: `/user/deleteById/${id}` }, options);
}

/**
 * 添加团队成员
 * @param data 参数
 * @param options 配置
 */
export function addTeamMember(data: AddMemberRequest, options?: RequestOptions) {
  return request.post({ url: '/user/addSysUser', data }, options);
}

/**
 * ====================
 *        消息通知
 * ====================
 */
/**
 * 获取通知列表
 * @param options 请求配置
 */
export function getNoticeList(params: PageSearchParams, options?: RequestOptions) {
  return request.get<PaginationData<Notice>>({ url: '/notice/queryNoticeList', params }, options);
}

/**
 * ====================
 *        回收站
 * ====================
 */
/**
 * 获取回收站列表
 * @param options 请求配置
 */
export function getRecycleList(params: PageSearchParams, options?: RequestOptions) {
  return request.get<PaginationData<Recycle>>({ url: '/notice/queryRecycleList', params }, options);
}

/**
 * 还原回收站
 * @param data 参数
 * @param options 配置
 */
export function restore(id: number, options?: RequestOptions) {
  return request.put({ url: `/notice/updateById/${id}` }, options);
}
