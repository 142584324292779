<template>
  <t-config-provider :global-config="globalConfig">
    <router-view />
  </t-config-provider>

  <!-- 到期提示 -->
  <collapse-transition v-if="showExp">
    <div class="fixed top-0 left-0 right-0 z-[2000]">
      <div class="bg-brand-focus h-[56px] flex gap-2 items-center px-16">
        <sound-icon class="text-warning" size="20px" />
        <div class="flex-1">
          {{ expStr }}
          <t-popup placement="bottom" trigger="click">
            <t-link theme="primary">联系客服续费</t-link>
            <template #content>
              <t-image
                v-if="userStore.teamInfo.salePerson"
                :src="userStore.teamInfo.salePerson.wx"
                class="w-[200px] my-6 !bg-transparent"
              />
            </template>
          </t-popup>
        </div>
        <chevron-up-circle-icon
          class="text-secondary cursor-pointer hover:text-brand"
          size="16px"
          @click="canShowExp = false"
        />
      </div>
    </div>
  </collapse-transition>

  <!-- 浏览器兼容 -->
  <collapse-transition>
    <div v-if="incompatible" class="fixed bottom-0 left-0 right-0 z-[2000] p-4 bg-white shadow flex items-center">
      <div class="flex mx-auto">
        <div class="ftext-primary mr-20">
          <div class="text-xl font-bold mb-6">需要更新浏览器以获得最佳体验</div>
          <div>为保证良好的使用效果，我们建议您升级浏览器或下载我们推荐的浏览器进行访问</div>
        </div>
        <div>
          <div class="mb-2 w-[140px]">
            <t-button
              class="!justify-start"
              theme="default"
              block
              @click="() => open('https://www.google.cn/chrome/index.html')"
            >
              <template #icon>
                <img
                  src="https://www.google.cn/chrome/static/images/favicons/favicon-32x32.png"
                  class="w-[16px] mr-1"
                />
              </template>
              下载 Chrome
            </t-button>
          </div>
          <div class="w-[140px]">
            <t-button
              class="!justify-start"
              theme="default"
              block
              @click="() => open('https://www.microsoft.com/zh-cn/edge/download')"
            >
              <template #icon>
                <img src="https://edgestatic.azureedge.net/welcome/static/favicon.png" class="w-[16px] mr-1" />
              </template>
              下载 Edge
            </t-button>
          </div>
        </div>
      </div>
      <t-button class="!absolute top-2 right-2" theme="default" shape="circle" @click="incompatible = false">
        <template #icon><close-icon /></template>
      </t-button>
    </div>
  </collapse-transition>

  <!-- 已到期提醒 -->
  <expired-dialog />

  <!-- 已冻结提醒 -->
  <t-dialog
    v-if="userStore.teamInfo"
    v-model:visible="userStore.frozenVisible"
    :width="400"
    :close-btn="false"
    :close-on-esc-keydown="false"
    :close-on-overlay-click="false"
    destroy-on-close
    :cancel-btn="null"
    confirm-btn="我知道了"
    @confirm="frozenConfirm"
  >
    <div class="flex flex-col items-center">
      <div class="text-primary text-base font-bold">很抱歉，您的账号已被冻结</div>
      <t-image
        v-if="userStore.teamInfo.salePerson"
        :src="userStore.teamInfo.salePerson.wx"
        class="w-[200px] my-4 !bg-transparent"
      />
      <t-tag theme="primary" size="large">微信扫码立即咨询</t-tag>
    </div>
  </t-dialog>
</template>

<script setup lang="ts">
import { base64Reg } from '@meerkat/common/pattern';
import { useBrowserCompatibility } from '@meerkat/hooks';
import { nanoid } from 'nanoid';
import { ChevronUpCircleIcon, CloseIcon, SoundIcon } from 'tdesign-icons-vue-next';
import { GlobalConfigProvider } from 'tdesign-vue-next';
import { computed, onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';

import { useUserStore } from '@/store';

const userStore = useUserStore();
const router = useRouter();

onBeforeMount(() => {
  window.name = `meerkat-${nanoid()}`;

  browserCheck();
});

/**
 * ====================
 *       基本逻辑
 * ====================
 */
const globalConfig: GlobalConfigProvider = {
  dialog: {
    closeOnOverlayClick: false,
  },
  drawer: {
    closeOnOverlayClick: false,
  },
  image: {
    loadingText: '',
    errorText: '',
    replaceImageSrc(params) {
      if (!params.src) {
        return '';
      }

      // 临时解决 params.src 可能会是 File 的问题
      if (typeof params.src !== 'string') {
        return params.src.name;
      }

      // 如果地址 已经包含样式 或者 非猫鼬云文件空间 则 返回原始地址
      if (
        /!((w\d{1,})|(original))$/.test(params.src) ||
        base64Reg.test(params.src) ||
        !params.src.includes('https://file.data-vib.com')
      ) {
        return params.src;
      }

      console.warn('当前页面存在图片未加样式', params.src);
      return `${params.src}!original`;
    },
  },
};

/**
 * ====================
 *     浏览器版本检测
 * ====================
 */
const { incompatible, browserCheck } = useBrowserCompatibility();
/**
 * 跳转外部地址
 * @param url 地址
 */
const open = (url: string) => {
  window.open(url);
};

/**
 * ====================
 *       到期通知
 * ====================
 */
const showExp = computed(() => {
  return canShowExp.value && userStore.residueDays <= 10 && router.currentRoute.value.path !== '/login';
});
const canShowExp = ref(true);
const expStr = computed(() => {
  if (userStore.residueDays <= 0) {
    return '当前付费服务已到期，请及时续费，以免影响您正常使用。';
  }
  if (userStore.residueDays <= 1) {
    return '当前付费服务即将到期，请及时续费，以免影响您正常使用。';
  }
  return `当前付费服务还剩 ${Math.floor(userStore.residueDays)} 天，请及时续费，以免影响您正常使用。`;
});

/**
 * ====================
 *      已冻结弹窗
 * ====================
 */
/**
 * 冻结确认
 */
const frozenConfirm = () => {
  userStore.switchFrozenDialogVisible(false);
  canShowExp.value = false;
  router.replace('/login');
};
</script>

<style lang="less" scoped>
#nprogress .bar {
  background: var(--td-brand-color) !important;
}

:global(.t-swiper__content) {
  border-radius: 12px;
}
:global(.contact-popup .t-popup__content) {
  padding: 0;
  box-shadow: none;
  background: none;
}
</style>
