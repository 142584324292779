import { defineStore } from 'pinia';

interface GuideStore {
  /**
   * 已读引导
   */
  readGuide: guideNames[];
}

/**
 * 引导名称
 * @describe chat: 聊天;
 */
type guideNames = 'chat';

export const useGuideStore = defineStore('guide', {
  state: () => {
    const data: GuideStore = {
      readGuide: [],
    };
    return data;
  },
  actions: {
    /**
     * 设为已读
     * @param name 引导名称
     */
    setRead(name: guideNames) {
      if (this.readGuide.includes(name)) {
        return;
      }

      this.readGuide.push(name);
    },
  },
  persist: true,
});
