<template>
  <t-progress v-bind="props" :percentage="percentage" />
</template>

<script lang="ts" setup>
import { ProgressProps } from 'tdesign-vue-next';
import { computed, onBeforeMount, watch } from 'vue';

defineOptions({
  name: 'MProgress',
});
const percentage = defineModel<number>('modelValue', { required: false, default: 0 });
const props = defineProps<
  {
    /**
     * 自动增加
     */
    autoIncrease?: number | boolean;
  } & ProgressProps
>();
watch(
  () => [props.autoIncrease, percentage.value],
  () => {
    init();
  },
);

onBeforeMount(() => {
  init();
});

/**
 * ====================
 *       基本逻辑
 * ====================
 */
/**
 * 初始化
 */
function init() {
  if (!autoIncreaseTimer && props.autoIncrease) {
    doAutoInc();
  } else if (autoIncreaseTimer && !props.autoIncrease) {
    clearTimeout(autoIncreaseTimer);
  } else if (percentage.value >= 100) {
    percentage.value = 100;
    clearTimeout(autoIncreaseTimer);
  }
}
let autoIncreaseTimer: NodeJS.Timeout;
const autoIncDelay = computed(() => {
  if (!props.autoIncrease) {
    return 0;
  }

  if (props.autoIncrease === true) {
    return 100;
  }

  return props.autoIncrease;
});
/**
 * 进行自增
 */
function doAutoInc() {
  if (!autoIncDelay.value || percentage.value >= 99) {
    clearTimeout(autoIncreaseTimer);
    return;
  }

  autoIncreaseTimer = setTimeout(() => {
    if (percentage.value >= 99) {
      return;
    }

    autoIncreaseTimer = undefined;
    percentage.value = parseFloat((percentage.value + 0.1).toFixed(1));
    doAutoInc();
  }, autoIncDelay.value);
}
</script>
