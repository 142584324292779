import Layout from '@/layouts/index.vue';

export default [
  {
    path: '/system',
    component: Layout,
    name: 'System',
    redirect: '/system/group',
    children: [
      {
        path: 'group',
        name: 'SystemGroup',
        component: () => import('@/pages/system/Group.vue'),
        meta: { title: '团队管理', back: true },
      },
    ],
  },
];
