import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { defineStore } from 'pinia';

import { TeamInfo } from '@/api/model/teamModel';
import { UserInfo } from '@/api/model/userModel';
import { getTeamInfo } from '@/api/team';
import { getUserInfo } from '@/api/user';

interface UserStore {
  /**
   * 白名单
   */
  whiteListRouters: string[];
  /**
   * 令牌
   */
  token?: string;
  /**
   * 来自哪里登录
   * @describe self: 自己; kingDee: 金蝶管易云;
   */
  loginFrom: 'self' | 'kingDee';
  /**
   * 用户信息
   */
  userInfo?: UserInfo;
  /**
   * 用户信息的刷新时间
   */
  userInfoTimestamp?: number;
  /**
   * 团队信息
   */
  teamInfo: TeamInfo;
  /**
   * 是否显示已到期弹窗
   */
  expiredVisible: boolean;
  /**
   * 是否禁用到期弹窗
   */
  expiredVisibleDisable: boolean;
  /**
   * 是否显示冻结弹窗
   */
  frozenVisible: boolean;
}

export const useUserStore = defineStore('user', {
  state: () => {
    const data: UserStore = {
      whiteListRouters: ['/login'],
      token: undefined,
      loginFrom: undefined,
      userInfo: undefined,
      userInfoTimestamp: 0,
      teamInfo: undefined,
      expiredVisible: false,
      expiredVisibleDisable: false,
      frozenVisible: false,
    };
    return data;
  },
  getters: {
    /**
     * 可用权限列表
     * @param state 状态
     */
    permissions: (state) => {
      // const all = [
      //   'NoAccess',
      //   'Home',
      //   'ProjectPlanning',
      //   'ProjectDefine',
      //   'ProjectRD',
      //   'ProjectLaunch',
      //   'ProjectSpecial',
      //   'ProjectKingDee',
      //   'ProjectHuaxizi',
      //   'ProjectChat',
      //   'ProjectChatHistory',
      //   'ProjectChatResult',
      //   'ProjectChatResultEditor',
      //   'SpaceKnowledge',
      //   'SpaceKnowledgeDetail',
      //   'SpaceKnowledgeEditor',
      //   'SpaceDigitalHumanKnowledge',
      //   'MaoDb',
      // ];
      const permissions: { allow: string[]; notAllow: string[] } = {
        allow: [],
        notAllow: [],
      };

      if (state.loginFrom === 'kingDee') {
        // 金蝶管易云模式
        permissions.allow = [
          'NoAccess',
          'ProjectKingDee',
          'ProjectChat',
          'ProjectChatHistory',
          'ProjectChatResult',
          'ProjectChatResultEditor',
        ];
      } else if (state.teamInfo.companyType === 'florasis') {
        // 花西子
        permissions.allow = [
          'NoAccess',
          'Home',
          'ProjectHuaxizi',
          'ProjectChat',
          'ProjectChatHistory',
          'ProjectChatResult',
          'ProjectChatResultEditor',
        ];
      } else if (state.teamInfo.companyType === 'yum') {
        // 百盛
        permissions.allow = [
          'NoAccess',
          'Home',
          'ProjectPlanning',
          'ProjectDefine',
          'ProjectRD',
          'ProjectLaunch',
          'ProjectSpecial',
          'ProjectChat',
          'ProjectChatHistory',
          'ProjectChatResult',
          'ProjectChatResultEditor',
          'SpaceKnowledge',
          'SpaceKnowledgeDetail',
          'SpaceKnowledgeEditor',
          'SpaceDigitalHumanKnowledge',
          'MaoDb',
        ];
      } else if (state.teamInfo.companyType === 'osm') {
        // osm: 欧诗漫;
        permissions.allow = ['NoAccess', 'ProjectBlankChat'];
      } else {
        permissions.allow = ['*'];
        permissions.notAllow = ['ProjectKingDee', 'ProjectHuaxizi', 'MaoDb'];
      }

      return permissions;
    },
    /**
     * 判断是否拥有权限
     * @param name 权限名称
     */
    hasPermission() {
      const permissions = this.permissions as { allow: string[]; notAllow: string[] };
      return (name: string) => {
        if (permissions.notAllow.length && permissions.notAllow.includes(name)) {
          return false;
        }

        return permissions.allow.includes('*') || permissions.allow.includes(name);
      };
    },
    /**
     * 用户id
     * @param state 状态
     */
    id: (state) => {
      if (!state.userInfo) {
        return undefined;
      }
      return state.userInfo.userId;
    },
    /**
     * 用户名
     * @param state 状态
     */
    name: (state) => {
      if (!state.userInfo) {
        return '';
      }
      if (state.userInfo.nickName) {
        return state.userInfo.nickName;
      }
      if (state.userInfo.phonenumber) {
        return state.userInfo.phonenumber;
      }
      return state.userInfo.userName;
    },
    /**
     * 名称首字
     */
    firstWord() {
      if (!this.name) {
        return '-';
      }

      return this.name[0];
    },
    /**
     * 到期时间
     */
    expiredTime() {
      if (!this.teamInfo) {
        return undefined;
      }

      return this.teamInfo.serveTime;
    },
    /**
     * 到期剩余毫秒数
     */
    residueMilliseconds() {
      return new Date(this.expiredTime).getTime() - new Date().getTime();
    },
    /**
     * 到期剩余天数
     */
    residueDays() {
      return Math.ceil(this.residueMilliseconds / 86400000);
    },
    /**
     * 是否已过期
     */
    isExpired() {
      return this.residueMilliseconds < 0;
    },
    /**
     * 是否已冻结
     */
    isFrozen() {
      return this.teamInfo.openStatus === 0;
    },
    /**
     * Token是否还有剩余
     */
    hasToken() {
      if (!this.teamInfo) {
        return false;
      }
      return this.teamInfo.leaveNum > 0;
    },
  },
  actions: {
    /**
     * 获取用户信息
     * @param force 强制刷新
     */
    async getUserInfo(force = false) {
      const current = dayjs().unix();

      if (this.userInfo && force === false) {
        const threshold = 600; // 10分钟
        if (current - this.userInfoTimestamp < threshold) {
          return;
        }
      }
      const res = await getUserInfo();
      this.userInfoTimestamp = current;
      this.userInfo = res.user;
    },
    /**
     * 退出登录
     */
    async logout() {
      this.token = '';
      this.userInfo = undefined;
    },
    /**
     * 获取团队信息
     */
    getTeamInfo() {
      return new Promise<void>((resolve, reject) => {
        getTeamInfo()
          .then((res) => {
            this.teamInfo = res;
            this.checkAccountStatus(['expired', 'frozen']);

            resolve();
          })
          .catch((err) => reject(err));
      });
    },
    /**
     * 检测指定账户状态
     * @param content 需要检测的内容
     */
    checkAccountStatus(content: ('token' | 'expired' | 'frozen')[] = ['expired', 'frozen', 'token']) {
      const gateway = {
        token: this.checkToken,
        expired: this.checkExpired,
        frozen: this.checkFrozen,
      };

      for (const way of content) {
        if (!gateway[way]) {
          continue;
        }

        if (!gateway[way]()) {
          return false;
        }
      }

      return true;
    },
    /**
     * 检测账户是否过期，如已过期则弹窗提示
     */
    checkExpired() {
      if (this.isExpired || (this.residueDays <= 3 && this.teamInfo.probationFlag === 1)) {
        if (!this.expiredVisibleDisable) {
          this.switchExpiredDialogVisible(true);
        }
      }

      return !this.isExpired;
    },
    /**
     * 修改已到期弹窗显示状态
     * @param visible 显示状态
     */
    switchExpiredDialogVisible(visible: boolean) {
      this.expiredVisible = visible;
      if (!visible) {
        this.expiredVisibleDisable = true;
      }
    },
    /**
     * 检测账户是否冻结，如已冻结则弹窗提示
     */
    checkFrozen() {
      if (this.isFrozen) {
        this.switchFrozenDialogVisible(true);
      }

      return !this.isFrozen;
    },
    /**
     * 修改冻结弹窗显示状态
     * @param visible 显示状态
     */
    switchFrozenDialogVisible(visible: boolean) {
      this.frozenVisible = visible;
    },
    /**
     * 检测token是否还有剩余，如无剩余则弹窗提示
     */
    checkToken() {
      return true;
      // if (!this.hasToken) {
      //   const confirm = DialogPlugin.confirm({
      //     header: 'Token不足',
      //     body: '很抱歉，您当前剩余token数不足',
      //     theme: 'warning',
      //     cancelBtn: null,
      //     onConfirm: () => {
      //       confirm.destroy();
      //     },
      //   });
      // }

      // return this.hasToken;
    },
  },
  persist: {
    key: 'user',
    paths: ['loginFrom'],
    beforeRestore: (ctx) => {
      const domain = import.meta.env.VITE_COOKIE_DOMAIN;
      const token = Cookies.withAttributes({ domain }).get('sassToken');
      if (token) {
        ctx.store.token = token;
      }
    },
  },
});
