import { createAxios } from '@meerkat/utils';

import router from '@/router';
import { useUserStore } from '@/store';

export const request = createAxios({
  getToken: () => {
    const userStore = useUserStore();
    return userStore.token;
  },
  authInvalid() {
    router.replace('/login');
  },
  host: import.meta.env.VITE_API_URL,
});
