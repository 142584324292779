<template>
  <div class="pl-2 space-y-1">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { useVModel } from '@vueuse/core';
import { cloneDeep } from 'lodash-es';
import { onBeforeMount, provide, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    /**
     * 绑定值
     */
    modelValue: string;
    /**
     * 默认值
     */
    defaultValue?: string;
  }>(),
  {},
);
const emits = defineEmits<{
  /**
   * 更新值
   * @param value 值
   */
  'update:modelValue': [value: string];
  /**
   * 更新回调
   * @param value 值
   */
  change: [value: string];
}>();

onBeforeMount(() => {
  defValue.value = cloneDeep(props.defaultValue);
});

/**
 * ====================
 *       基本逻辑
 * ====================
 */
const defValue = ref('');
const checkedValue = useVModel(props, 'modelValue', emits, { defaultValue: defValue.value });
provide('checkedValue', checkedValue);
provide('changeCheckedValue', (value: string) => {
  checkedValue.value = value;
  emits('change', value);
});
</script>

<style lang="less" scoped />
