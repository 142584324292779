<template>
  <div
    class="h-[46px] pr-6 pl-[calc(24px+30px*var(--level))] rounded-lg justify-start items-center gap-2 flex cursor-pointer transition-all hover:bg-brand-focus"
    :class="{ 'bg-brand-light': checked }"
    :style="{ '--level': currentLevel }"
    @click="onClick"
  >
    <div v-if="$slots.icon" class="w-[20px] text-xl leading-none mr-2">
      <slot name="icon" :checked="checked" />
    </div>
    <div
      class="text-sm transition-all break-all line-clamp-1"
      :class="{ 'text-brand-active': checked, 'text-gray-9': !checked }"
    >
      {{ props.label }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, inject, ref } from 'vue';

const props = defineProps<{
  /**
   * 值
   */
  value: string;
  /**
   * 文本
   */
  label: string;
  /**
   * 跳转地址
   */
  href?: string;
}>();

/**
 * ====================
 *       基本逻辑
 * ====================
 */
// 选中项
const checkedValue = inject('checkedValue', ref());
const changeCheckedValue = inject<(value: string) => void>('changeCheckedValue');
const checked = computed(() => {
  return checkedValue.value === props.value;
});
/**
 * 点击选中
 */
const onClick = () => {
  if (props.href) {
    window.open(props.href);
    return;
  }

  changeCheckedValue(props.value);
};
const currentLevel = inject('currentLevel', 0);
</script>

<style lang="less" scoped />
