import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const env = import.meta.env.MODE || 'development';

// 导入modules固定路由
const fixedModules = import.meta.glob('./modules/**/**.ts', { eager: true });

// 其他固定路由
const defaultRouterList: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/login/index.vue'),
  },
  {
    path: '/kingDee',
    name: 'KingDeeOAuth',
    component: () => import('@/pages/login/kingDee.vue'),
  },
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/:w+',
    name: '404Page',
    redirect: '/result/404',
  },
];
// 存放固定路由
export const fixedRouterList: Array<RouteRecordRaw> = mapModuleRouterList(fixedModules);

export const allRoutes = [...fixedRouterList, ...defaultRouterList];

// 固定路由模块转换为路由
export function mapModuleRouterList(modules: Record<string, unknown>): Array<RouteRecordRaw> {
  const routerList: Array<RouteRecordRaw> = [];
  Object.keys(modules).forEach((key) => {
    // @ts-ignore
    const mod = modules[key].default || {};
    const modList = Array.isArray(mod) ? [...mod] : [mod];
    routerList.push(...modList);
  });
  return routerList;
}

const history = createWebHistory(env === 'site' ? '/starter/vue-next/' : import.meta.env.VITE_BASE_URL);

const router = createRouter({
  history,
  routes: allRoutes,
  scrollBehavior() {
    return {
      el: '#app',
      top: 0,
      behavior: 'smooth',
    };
  },
});

export default router;

export { history };
