import Layout from '@/layouts/index.vue';

export default [
  {
    path: '/space',
    component: Layout,
    name: 'Space',
    children: [
      {
        path: 'knowledge',
        name: 'SpaceKnowledge',
        component: () => import('@/pages/space/knowledge.vue'),
        meta: { title: '知识库' },
      },
      {
        path: 'knowledge/:id',
        name: 'SpaceKnowledgeDetail',
        component: () => import('@/pages/space/KnowledgeDetail.vue'),
        meta: { title: '知识库', hidden: true },
      },
      {
        path: 'knowledge/:type/:id/:folderId?',
        name: 'SpaceKnowledgeEditor',
        component: () => import('@/pages/space/KnowledgeBaseEditor.vue'),
        meta: { title: '知识库', hidden: true },
      },
      {
        path: 'digitalHumanKnowledge/:id',
        name: 'SpaceDigitalHumanKnowledge',
        component: () => import('@/pages/space/DigitalHumanKnowledge.vue'),
        meta: { title: '数字人关联知识库', hidden: true },
      },
    ],
  },
];
