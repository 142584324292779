<!-- Markdown编辑器 -->
<template>
  <div :id="id" />
</template>

<script setup lang="ts">
import 'cherry-markdown/dist/cherry-markdown.min.css';

import Cherry from 'cherry-markdown/dist/cherry-markdown.core';
import { CherryToolbarOptions } from 'cherry-markdown/types/cherry';
import { nanoid } from 'nanoid';
import { onMounted, ref, watch } from 'vue';

defineOptions({
  name: 'MarkdownEditor',
});
const props = withDefaults(
  defineProps<{
    /**
     * 工具栏配置
     */
    toolbar?: CherryToolbarOptions['toolbar'];
    /**
     * 自定义菜单
     */
    customMenu?: Record<string, any>;
    /**
     * 右侧工具栏配置
     * @default ```[]```
     */
    toolbarRight?: CherryToolbarOptions['toolbarRight'];
  }>(),
  { customMenu: () => ({}), toolbarRight: () => [] },
);
const modelValue = defineModel<string>('modelValue', { required: true });

onMounted(() => {
  cherryInstance = new Cherry({
    id: id.value,
    value: modelValue.value,
    toolbars: {
      theme: 'light',
      toolbar: props.toolbar || defaultToolbar,
      customMenu: props.customMenu,
      toolbarRight: props.toolbarRight,
    },
    callback: {
      afterChange: (text) => {
        modelValue.value = text as string;
      },
      afterInit: () => {},
      beforeImageMounted: (srcProp, src) => ({ srcProp, src }),
      onClickPreview: () => {},
      onCopyCode: (e, code) => code,
      changeString2Pinyin: (str) => str,
    },
  });

  // eslint-disable-next-line no-console
  console.log(cherryInstance.defaultToolbar);
});

/**
 * ====================
 *       基本逻辑
 * ====================
 */
const id = ref(`markdownEditor${nanoid()}`);
let cherryInstance: Cherry;
const defaultToolbar = [
  'bold',
  'italic',
  'strikethrough',
  '|',
  'color',
  'header',
  'ruby',
  '|',
  'list',
  'panel',
  'detail',
  {
    insert: [
      'image',
      'audio',
      'video',
      'link',
      'hr',
      'br',
      'code',
      'formula',
      'toc',
      'table',
      'line-table',
      'bar-table',
      'pdf',
      'word',
    ],
  },
  'graph',
  'settings',
];
watch(modelValue, (val) => {
  cherryInstance.setMarkdown(val || '', true);
});
</script>

<style lang="less" scoped></style>
