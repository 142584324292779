import { RequestOptions } from '@meerkat/utils';

import { request } from '@/utils/request';

import {
  LoginParams,
  LoginResponse,
  SetCategoryWithLoginParams,
  UpdateUserCenterRequest,
  UserCenterInfo,
  UserInfo,
} from './model/userModel';

/**
 * ====================
 *         登录
 * ====================
 */
/**
 * 登录
 * @param data 请求参数
 * @param options 请求配置
 */
export function login(data: LoginParams, options?: RequestOptions) {
  return request.post<LoginResponse>({ url: '/login', data }, { errorMessage: false, ...options });
}

/**
 * 第三方登录
 * @param data 请求参数
 * @param options 请求配置
 */
export function thirdPartyLogin(data: { phone: string; isType: 10 }, options?: RequestOptions) {
  return request.post<{ tokenVo: LoginResponse }>(
    { url: '/thirdPartyLogin', data },
    { loading: true, loadingText: '正在鉴权', ...options },
  );
}

/**
 * 发送登录验证码
 * @param data 请求参数
 * @param options 请求配置
 */
export function sendLoginCode(data: LoginParams, options?: RequestOptions) {
  return request.post({ url: '/getMsgCode', data }, options);
}

/**
 * 忘记密码
 * @param data 请求参数
 * @param options 请求配置
 */
export function forgetPassword(data: LoginParams, options?: RequestOptions) {
  return request.post<LoginResponse>({ url: '/regPassword', data }, { errorMessage: false, ...options });
}

/**
 * 获取登录用户用户信息
 * @param options 请求配置
 */
export function getUserInfo(options?: RequestOptions) {
  return request.get<{ user: UserInfo }>({ url: '/getInfo' }, options);
}

/**
 * 登录阶段强制设定密码
 * @param data 请求参数
 * @param options 请求配置
 */
export function setPasswordWithLogin(data: LoginParams, options?: RequestOptions) {
  return request.post({ url: '/updateLoginPassword', data }, { errorMessage: false, ...options });
}

/**
 * 跳过登录阶段强制设定密码
 * @param options 请求配置
 */
export function skipSetPasswordWithLogin(options?: RequestOptions) {
  return request.post({ url: '/skipStep' }, { errorMessage: false, ...options });
}

/**
 * 登录阶段强制设定品类
 * @param data 请求参数
 * @param options 请求配置
 */
export function setCategoryWithLogin(data: SetCategoryWithLoginParams, options?: RequestOptions) {
  return request.put({ url: '/user/bingSysUserClass', data }, { errorMessage: false, ...options });
}

/**
 * ====================
 *       个人中心
 * ====================
 */
/**
 * 获取个人中心数据
 * @param params 请求配置
 * @param options 请求配置
 */
export function getUserCenterInfo(options?: RequestOptions) {
  return request.get<UserCenterInfo>({ url: '/user/getSysUserCenter' }, options);
}

/**
 * 修改个人中心数据
 * @param data 请求参数
 * @param options 请求配置
 */
export function updateUserCenterInfo(data: UpdateUserCenterRequest, options?: RequestOptions) {
  return request.put({ url: '/user/updateSysUserCenter', data }, { errorMessage: false, ...options });
}

/**
 * 修改用户中心密码
 * @param data 参数
 * @param options 配置
 */
export function updateCenterPassword(data: LoginParams, options?: RequestOptions) {
  return request.post({ url: '/updatePassword', data }, { errorMessage: false, ...options });
}
