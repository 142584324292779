<template>
  <div class="w-full h-full flex gap-6 justify-center items-center">
    <slot name="imgUrl">
      <img class="w-[120px] h-[120px]" :src="emptyPng" />
    </slot>
    <div class="w-[200px]">
      <p class="text-primary text-base font-medium">{{ props.title }}</p>
      <p v-if="props.description" class="text-placeholder text-sm my-3">{{ props.description }}</p>
      <t-button
        v-if="props.btnText"
        :theme="props.type === 'base' ? 'primary' : 'default'"
        :variant="props.type === 'base' ? 'base' : 'outline'"
        @click="emits('toCreate')"
      >
        <template #icon><add-icon /></template>
        {{ props.btnText }}
      </t-button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { AddIcon } from 'tdesign-icons-vue-next';

import emptyPng from './empty.png';

defineOptions({
  name: 'Empty',
});
const props = defineProps<{
  /**
   * 空状态标题
   */
  title: string;
  /**
   * 空状态描述
   */
  description?: string;
  /**
   * 显示的按钮类型
   */
  type?: string;
  /**
   * 按钮文字内容
   */
  btnText?: string;
}>();
const emits = defineEmits<{
  /**
   * 新建项目
   */
  toCreate: [];
}>();
</script>
