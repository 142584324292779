<!-- markdown 展示器 -->
<template>
  <div class="marked cherry-markdown" v-html="html" />
</template>

<script setup lang="ts">
import 'cherry-markdown/dist/cherry-markdown.min.css';

import CherryEngine from 'cherry-markdown/dist/cherry-markdown.engine.core.esm';
import { computed } from 'vue';

defineOptions({
  name: 'Marked',
});
const props = withDefaults(
  defineProps<{
    /**
     * 需要展示的 markdown 数据
     */
    content: string;
    /**
     * 是否显示光标
     * @default ```false```
     */
    cursor?: boolean;
  }>(),
  { cursor: false },
);

/**
 * ====================
 *       基本逻辑
 * ====================
 */
const html = computed(() => {
  let finalContent = props.content.replaceAll('\\n', '\n');

  // 处理光标
  if (props.cursor) {
    const codeBlock = '\n```'; // 避免被转义
    const cursorElm = '<span /> <span class="marked__cursor" />';
    if (!isLastCodeBlockClosed(finalContent)) {
      // 代码块未正确闭合
      finalContent = `${finalContent + codeBlock}\n\n${cursorElm}`;
    } else if (finalContent.trim().endsWith('```')) {
      // 最后的内容是代码块
      finalContent = `${finalContent}\n\n${cursorElm}`;
    } else {
      // 常规
      finalContent = `${finalContent} ${cursorElm}`;
    }
  }

  const cherryEngineInstance = new CherryEngine({}) as CherryEngine & { makeHtml(md: any): any };

  return cherryEngineInstance.makeHtml(finalContent);
});
/**
 * 代码块是否正确闭合
 * @param text 文本
 */
function isLastCodeBlockClosed(text: string) {
  // 找出所有的 "```"
  const matches = text.match(/```/g);

  if (!matches) {
    return true; // 如果没有 "```"，则认为没有未闭合的代码块
  }

  // 如果 "```" 的数量是奇数，那么最后一个代码块未闭合
  return matches.length % 2 === 0;
}
</script>

<style lang="less" scoped>
.marked {
  :deep(p) {
    margin: 0;
    line-height: 22px;
  }

  :deep(p) + p {
    margin-top: 16px;
  }

  :deep(.marked__cursor) {
    display: inline-block;
    width: 3px;
    height: 14px;
    background-color: black;
    animation: cursorBlink 0.8s infinite;
    position: relative;
    top: 2px;
    margin-left: 2px;
  }

  :deep(code) {
    word-break: break-all !important;
  }

  @keyframes cursorBlink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
